<template>
  <div class="notice">
    <form action="/">
      <van-search
          v-model="searchValue"
          show-action
          placeholder="请输入标题搜索"
          @search="onSearch"
          @cancel="onCancel"
      />
    </form>
    <div class="cont">
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="getDataList" offset="10">
        <van-cell v-for="item in dataList" :key="item.id" @click="getInfo(item.id)">
          <div class="item">
            <van-row>
              <van-col :span="22">
                <p>{{item.title}}</p>
              </van-col>
              <van-col :span="2">
                <van-icon name="arrow" />
              </van-col>
            </van-row>
            <van-row>
              <van-col :span="20">
                <span>时间: {{item.createTime}}</span>
              </van-col>
              <van-col :span="4">
                <span>{{item.createUserName}}</span>
              </van-col>
            </van-row>
          </div>
        </van-cell>
      </van-list>
      <van-empty description="没有数据哦" v-if="dataList.length < 1"/>
    </div>
  </div>
</template>

<script>
import topBar from '@/components/topBar/topBar'
export default {
  components :{
    topBar
  },
  data() {
    return {
      searchValue: '',
      loading: false,
      finished: false,
      limit: 10,
      page: 0,
      dataList: []
    };
  },
  methods: {
    getInfo (id) {
      this.$router.push('/sideNotice-info?id=' + id)
    },
    getDataList () {
      this.page++
      this.$toast.loading({duration: 0,message: '加载中...',forbidClick: true,});
      this.$http({
        url: this.$http.adornUrl('/wxapp/commonApp/notice/list'),
        method: 'post',
        params: this.$http.adornParams({
          page: this.page,
          limit: this.limit,
          title: this.searchValue,  //搜索条件
          appOrgId: this.$orgId,
          isNpcNotice: 1
        })
      }).then(({data})=> {
        if (data.code == 0) {
          console.log(data);
          if (this.dataList.length == data.page.totalCount) {
            this.finished = true
          }
          this.dataList = this.dataList.concat(data.page.list)
          // 加载状态结束
          this.dataList.sort(function(a,b){return a.createTime < b.createTime ? 1 : -1})
          this.loading = false;
          this.$toast.clear()
        }
        this.$toast.clear()
      },err=> {this.$toast.clear()})
      // 数据全部加载完成
    },
    onSearch(val) {
      this.page = 0
      this.dataList = []
      this.getDataList()
    },
    onCancel() {
      this.page = 0
      this.dataList = []
      this.searchValue = ''
      this.getDataList()
    },
  },
  created () {
    this.page = 0
    this.limit = 10
    this.getDataList()
  }
}
</script>

<style lang="scss" scoped>
.notice {
  padding-top: 100px;
  min-height: 100vh;
  background-color: #f5f5f5;
  .cont {
    background-color: #f5f5f5;
    .van-list > .van-cell {
      padding: 15px 15px;
    }
    .item {
      p {
        margin: 10px 0;
        font-size: 30px;
        font-weight: 600;
      }
      span {
        color: #666;
        font-size: 26px;
      }
    }
  }
}
.van-icon-arrow::before {
  margin-top: 28px;
}
</style>
